import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { RotateLoader } from "react-spinners";
import { Helmet } from "react-helmet";
import { UserContext } from "../Context/Context";
import "video-react/dist/video-react.css"; // import css
import { Player } from "video-react";
import Modal from 'react-bootstrap/Modal';

import { toast } from "react-toastify";
import { Button } from "react-bootstrap";
import { t } from "i18next";
import Shartviuo from "./ShartVeiduo";
import * as Yup from 'yup'; // استيراد Yup لإجراء التحقق من البيانات
import { Formik, Form, Field, ErrorMessage } from 'formik';// @import "~video-react/styles/scss/video-react"; // or import scss

export default function VeiduoDetails() {
  const [jobDetails, setJobDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [dataviduo, setdataviduo] = useState([]);
  const [comment, setComment] = useState("");
  const [cv, setcv] = useState([]);
  const [dawinloandate, setdawinloandate] = useState([]);
  const [activeValue, setActiveValue] = useState(false);
  const [dawinload, setdawinload] = useState([]);
  const [isCommentLoading, setIsCommentLoading] = useState(false);
  const [company_comment, setCompanyComment] = useState('');
  const [offer, setOffer] = useState('');
  const [status, setStatus] = useState('hired');
  const [show, setShow] = useState(false);
  const [Showw, setShoww] = useState(false);
  const [statuss, setStatuss] = useState('hired');
  const [data, setdata] = useState(true)
    const [companyCommentt, setCompanyCommentt] = useState('');
const [packageFields, setPackageFields] = useState(['']);
const [inputCount, setInputCount] = useState(1); // حالياً عدد الحقول هو 1
const [error, seterror] = useState("")

    const [isOpen, setIsOpen] = useState(false); // حالة لتحديد ما إذا كان يجب عرض ال <div> أم لا
    // const [isLoading, setIsLoading] = useState(false);

    const handleStatusChangee = (e) => {
        setStatus(e.target.value);
        setIsOpen(e.target.value === 'hired'); // عندما يتم تحديد Hired يجب فتح ال <div>
    };
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const handleShoww = () => setShoww(true);
  const handleClosee = () => setShoww(false);

  let { Url } = useContext(UserContext);
  const { id } = useParams();

  useEffect(() => {
    async function fetchData() {
      try {
        if (id) {
          const token = localStorage.getItem("UserToken");
          const headers = {
            Authorization: `Bearer ${token}`,
          };
          const response = await axios.get(`${Url}companies/jobs/${id}`, {
            headers: headers,
          });
          // console.log(response.data.data.cvs[0].cv , "response");
          setcv(response.data.data.cvs[0].cv);
          setJobDetails(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    }

    fetchData();
  }, [id]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (id) {
          const token = localStorage.getItem("UserToken");
          const headers = {
            Authorization: `Bearer ${token}`,
          };
          const response = await axios.get(
            `${Url}companies/jobs/${id}/interview`,
            {
              headers: headers,
            }
          );
          // console.log(response.data , "Ahmedd0000ddddddddffajjjjdddd");
          setdataviduo(response.data.data.interviews[0]);
          // console.log(response.data.data.interviews[0].video, "response");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [id]);
  const sendComment = async () => {
    try {
      setIsCommentLoading(true); // تعيين الحالة إلى true عند بدء الطلب
      const token = localStorage.getItem("UserToken");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.post(
        `${Url}companies/jobs/${id}/interview/${dataviduo.id}`,
        {
          status: "hired",
          comment: comment, // تضمين بيانات التعليق هنا
        },
        { headers }
      );
      console.log("Comment sent successfully:", response.data);
      if (response.data.status == true) {
        toast.success(response.data.message, {
          position: "top-center",
        });
      }
      // إعادة تحميل البيانات بعد الإرسال إذا كان ذلك ضرورياً
      // fetchData(); // هذه الدالة تأخذ البيانات من الخادم كما فعلت في useEffect الأولى
    } catch (error) {
      console.error("Error sending comment:", error);
      setIsError(true);
    } finally {
      setIsCommentLoading(false); // تعيين الحالة إلى false بعد الانتهاء من الطلب
    }
  };

  // conso-le.log(onl);
  const handleclickdawinload = async (id) => {
    try {
      const token = localStorage.getItem("UserToken");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.post(
        `${Url}companies/cv-job/active/${id}`,
        { active: "downloaded" },
        { headers: headers }
      );
      setdawinloandate(response);
      console.log(response.data);
      toast.success("You have viewed successfully", {
        position: "top-center",
      });
      // قم بتحديث الحالة المحلية بناءً على الرد من الخادم
      setActiveValue(true); // أو قم بتغيير القيمة بناءً على الرد الذي تتوقعه من الخادم
    } catch (error) {
      console.error("Error updating status:", error);
      // قم بإدارة الخطأ هنا
    }
  };
  const handleWatchedButtonClick = async (id) => {
    try {
      const token = localStorage.getItem("UserToken");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.post(
        `${Url}companies/cv-job/active/${id}`,
        { active: "viewed" },
        { headers: headers }
      );
      setdawinloandate(response);
      console.log(response.data);
      toast.success("You have viewed successfully", {
        position: "top-center",
      });
      // قم بتحديث الحالة المحلية بناءً على الرد من الخادم
      setActiveValue(true); // أو قم بتغيير القيمة بناءً على الرد الذي تتوقعه من الخادم
    } catch (error) {
      console.error("Error updating status:", error);
      // قم بإدارة الخطأ هنا
    }
  };
  useEffect(() => {
    async function fetchData() {
      try {
        if (id) {
          const token = localStorage.getItem("UserToken");
          const headers = {
            Authorization: `Bearer ${token}`,
          };

          const response = await axios.get(`${Url}companies/jobs/${id}`, {
            headers: headers,
          });
          // console.log(response?.data.data.cvs , "aHM00ED8754441");
          setdawinloandate(response?.data.data.cvs);
          setcv(response?.data?.data?.cvs);
          setJobDetails(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    }

    fetchData();
  }, [id]);
  // --------------------------------------------------------
  useEffect(() => {
    async function fetchData() {
      try {
        if (id) {
          const token = localStorage.getItem("UserToken");
          const headers = {
            Authorization: `Bearer ${token}`,
          };

          const response = await axios.get(`${Url}companies/jobs/${id}/interview`, {
            headers: headers,
          });
          console.log("response" , response.data.data?.interviews[0].id);
          setdawinload(response?.data?.data?.interviews[0].id)
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    }

    fetchData();
  }, [id]);

  const fetchDataInput = async (event) => {
    event.preventDefault(); // منع السلوك الافتراضي للزر
    try {
      if (id && dawinload) { // التحقق من قيمة dawinload
        setIsLoading(true);
        const token = localStorage.getItem("UserToken");
        const headers = {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        };

        const dataToSend = {
          status: "rejected",
          company_comment: company_comment,
          // offer: offer,
        };

        const response = await axios.post(
          `${Url}companies/jobs/${id}/interview/${dawinload}`, // تضمين dawinload في عنوان الطلب
          dataToSend,
          { headers: headers }
        );
          if(response.data.status == true){
            toast.success(response.data.message, {
              position: "top-center",
            });
          }
        // console.log("response", );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  if (isLoading) {
    return (
      <div className="d-center mt-5">
        <RotateLoader
          height="80"
          width="80"
          radius="9"
          color="green"
          ariaLabel="loading"
          wrapperStyle
          wrapperClass
        />
      </div>
    );
  }
 
  const departmentName = jobDetails.department?.name || "N/A"

 

  const initialValues = {
    company_comment: '',
    offer: {
        pack: [''], // Initialize with an empty string
    },
};

const handleAddInput = () => {
    setInputCount(inputCount + 1); // زيادة عدد الحقول بمقدار 1
};

// يحدد تحقق البيانات باستخدام Yup
const validationSchema = Yup.object().shape({
  company_comment: Yup.string().required('Company comment is required'), // الحقل مطلوب
  starting: Yup.string().required("idhifvsdb"), 
  offer: Yup.object().shape({
      from_name: Yup.string(), // الحقل اختياري
      starting: Yup.string().required("Company comment is starting"), // الحقل اختياري
      job_title: Yup.string().required("Company comment is job_title"), // الحقل اختياري
      from_name: Yup.string().required("Company comment is from_name"), // الحقل اختياري
      date: Yup.string().required("Company comment is date"), // الحقل اختياري
      from_name: Yup.string().required("Company comment is from_name"), // الحقل اختياري
      reporting_to: Yup.string().required("Company comment is reporting_to"), // الحقل اختياري
      location: Yup.string().required("Company comment is location"), // الحقل اختياري
      managing_name_partner: Yup.string().required("Company comment is managing_name_partner"), // الحقل اختياري
      salary: Yup.number().required("Company comment is salary"), // الحقل اختياري
      other_financially: Yup.string().required("Company comment is other_financially"), // الحقل اختياري
      working_days: Yup.number().required("Company comment is working_days"), // الحقل اختياري
      working_hours: Yup.number().required("Company comment is working_hours"), // الحقل اختياري
      notice_period: Yup.number().required("Company comment is notice_period"), // الحقل اختياري
      acceptance_date: Yup.date().required("Company comment is acceptance_date"), // الحقل اختياري
      package: Yup.array().of(Yup.string()), // يجب أن يكون كل عنصر من النوع string

  }),
});

const fetchDataInputt = async (values, { setSubmitting }) => {
  try {
      if (id && dawinload) {
          setIsLoading(true);
          const token = localStorage.getItem("UserToken");
          const headers = {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
          };

          const offerObject = {
              date: values.offer.date,
              from_name: values.offer.from_name,
              starting: values.offer.starting,
              job_title: values.offer.job_title,
              reporting_to: values.offer.reporting_to,
              location: values.offer.location,
              salary: values.offer.salary,
              other_financially: values.offer.other_financially,
              working_days: values.offer.working_days,
              working_hours: values.offer.working_hours,
              managing_name_partner: values.offer.managing_name_partner,
              acceptance_date: values.offer.acceptance_date,
              notice_period: values.offer.notice_period,
              package: values.offer.package,
          };

          const dataToSend = {
              status: "hired",
              company_comment: values.company_comment,
              offer: offerObject,
          };

          const response = await axios.post(
              `${Url}companies/jobs/${id}/interview/${dawinload}`,
              dataToSend,
              { headers: headers }
          );
          setdata(response.data)
          seterror(response?.data?.errors)
          if (response.data.status === true) {
              toast.success(response.data.message, {
                  position: "top-center",
              });
              console.log(response?.data?.errors , "ahmed");
          }
           
          // error?.map((erro)=>{
          //   toast.error(error);
          //   console.log(erro , "erroerroerro");
          // })
       
          const errorsArray = Object.entries(error);

          // فحص ما إذا كانت هناك أخطاء
          if (errorsArray.length > 0) {
              errorsArray.map(([field, errorMessage]) => {
                  toast.error(`خطأ في الحقل "${field}": ${errorMessage}`);
              });
          } else {
              // إذا لم يكن هناك أخطاء
              toast.success("تم التحقق بنجاح!");
          }
          
          // console.log(error);
          // console.log(error);
          setIsLoading(false);
          setSubmitting(false);
      }
      // console.log(error , "error");
  } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
      setSubmitting(false);
      toast.error(data.message.errors);
  }
};



const handleAddPackageField = () => {
  setPackageFields([...packageFields, '']); // إضافة حقل package جديد
};


  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />

      </Helmet>
      <div className="container mt-4">
        <div className="row">
          <h5 className="pt-3 mx-1 mt-3 mb-3 text-color fw-bold">
            {jobDetails.name}
          </h5>

          <div className="col-6">
            <div className="pt-2 d-flex">
              <p className="fw-bold mx-2 font-12">التصنيف الرئيسي</p>
              <p>{departmentName}</p>
            </div>
            <div className="pt-0 d-flex">
              <p className="fw-bold mx-2 font-12">    {t("Experience")}  </p>
              <p>{jobDetails.category_level}</p>
            </div>
            <div className="pt-0 d-flex">
              <p className="fw-bold mx-2 font-12">     {t("startdate")} </p>
              <p>{jobDetails.start}</p>
            </div>
            <div className=" pb-2 text-center d-center"></div>
          </div>
          <div className="col-6">
            <div className="pt-2 d-flex ">
              <p className="fw-bold mx-2 font-12"> department</p>
              <p>{departmentName}</p>
            </div>
            <div className="pt-0 d-flex">
              <p className="fw-bold mx-2 font-12">    {t("skills")} </p>
              {/* Add a check for jobDetails.skills before mapping */}
              <p>
                {jobDetails.skills
                  ? jobDetails.skills.map((skill) => skill.name).join(", ")
                  : "N/A"}
              </p>
            </div>
            <div className="pt-0 d-flex">
              <p className="fw-bold mx-2 font-12">     {t("enddate")} </p>
              <p>{jobDetails.end}</p>
            </div>
            <div className=" pb-2 ">
              <div className="pb-2 text-center d-center "></div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-6">
              <video
                playsInline
                style={{ width: "100%", height: "auto" }}
                id="myVideo"
                autoPlay
                muted
                loop
                className="uvodna__bg-video"
                preload="auto"
                webkitPlaysInline
                x5PlaysInline
              >
                <source src={`${dataviduo?.video}`} type="video/mp4" />
                <source src={`${dataviduo?.video}`} type="video/ogg" />
              </video>
            </div>
            <div className="col-6">
              <div className="mt-4 d-flex">
        
                {/* ---------------------------------- */}
                <Button variant="" onClick={handleShow}  className="btn mt-3">
                <div className="btn btn-cv mt-1 font-12 mx-2">     {t("employee")}       </div>
              </Button>
                <Button variant="" onClick={handleShoww}  className="btn mt-3">
                <div className="btn btn-cv mt-1 font-12 mx-2">   {t("administration")}  </div>
              </Button>
              <div className="">
             <a
               href={`https://aihr.ahlanuof.com/${cv[0]?.cv}`}
               onClick={() => handleclickdawinload(cv.id)}
               target="_blank"
               rel="noopener noreferrer"
             >
               <button
                 className="btn btn-cv mt-1 font-12 mt-4"
                 onClick={() => handleWatchedButtonClick(cv.id)}
               >
                    {t("ViewCV")} 
               </button>
             </a>


           </div>
              </div>
        

              <Shartviuo/>


            </div>
          </div>
        </div>


        <Modal show={show} onHide={handleClose}>
  <div className="d-flex justify-content-between mt-3 mx-3">
    <Modal.Title> <h2 className="fw-bold">HR AI</h2> </Modal.Title>
    <Modal.Header closeButton />
  </div>
  <div className="w-100 px-2">
            <div className="w-100 px-3 pt-3 border-default">
                <h5 className="text-center text-color">Employee</h5>
                <div className="w-100 position-relative">
                    <select aria-label="Default select example" value={status} onChange={handleStatusChangee} className="form-control mb-3">
                        <option value="hired">Hired</option>
                        <option value="rejected">Rejected</option>
                    </select>
                    <i className="fa-solid fa-chevron-down position-absolute top-50 start-0 mx-3 translate-middle-y"></i>
              
              
                </div>

                <input
                    type="text"
                    className="form-control w-100"
                    value={companyCommentt}
                    onChange={(e) => setCompanyCommentt(e.target.value)}
                    placeholder="Enter Company Comment"
                />
<button type="button" onClick={fetchDataInput} disabled={isLoading} className="mb-3 active-button border-0 px-4 mt-3  pt-2 pb-2 fw-bold">Submit</button>

                {/* فتح ال <div> إذا كانت القيمة Hired */}
                {isOpen && (


<>
<Formik
    initialValues={initialValues}
    // validationSchema={validationSchema}
    onSubmit={fetchDataInputt}
>
    {({ isSubmitting }) => (
      <Formik
      initialValues={initialValues}
      // validationSchema={validationSchema}
      onSubmit={fetchDataInputt}
  >
      {({ isSubmitting }) => (
          <Form>
              <div className="mt-3 container">
                  <h5 className="fe-bold text-color text-center mt-2 mb-2">ارسال عرض الى الموظف</h5>
                  <div className="row">
                      <div className="col-md-12 mt-3">
                          <label htmlFor="companyComment" className="form-label">Enter Company Comment</label>
                          <Field
                              type="text"
                              name="company_comment"
                              id="companyComment"
                              className="form-control "
                              placeholder="Enter Company Comment"
                          />
                          <ErrorMessage name="company_comment" component="div" className="error-message bg-danger-alert" />
                      </div>
                      <div className="col-md-12 mt-3">
                          <label htmlFor="date" className="form-label">Offer Date</label>
                          <Field
                              type="date"
                              name="offer.date"
                              id="offerDate"
                              className="form-control"
                          />
                          <ErrorMessage name="offer.date" component="div" className="error-message bg-danger-alert" />
                      </div>
                      <div className="col-md-12 mt-3">
                          <label htmlFor="offerFromName" className="form-label">Offer From Name</label>
                          <Field
                              type="text"
                              name="offer.from_name"
                              id="offerFromName"
                              className="form-control"
                              placeholder="Enter Offer From Name"
                          />
                          <ErrorMessage name="offer.from_name" component="div" className="error-message bg-danger-alert" />
                      </div>
                      <div className="col-md-12 mt-3">
                          <label htmlFor="starting" className="form-label">starting</label>
                          <Field
                              type="date"
                              name="offer.starting"
                              id="starting"
                              className="form-control"
                              placeholder="Enter Offer From Name"
                          />
                          <ErrorMessage name="offer.starting" component="div" className="error-message  bg-danger-alert" />
                      </div>
                      <div className="col-md-12 mt-3">
                          <label htmlFor="job_title" className="form-label">job_title</label>
                          <Field
                              type="text"
                              name="offer.job_title"
                              id="job_title"
                              className="form-control"
                              placeholder="Enter job_title"
                          />
                          <ErrorMessage name="offer.job_title" component="div" className="error-message bg-danger-alert" />
                      </div>
                      <div className="col-md-12 mt-3">
                          <label htmlFor="reporting_to" className="form-label">reporting_to</label>
                          <Field
                              type="text"
                              name="offer.reporting_to"
                              id="reporting_to"
                              className="form-control"
                              placeholder="Enter reporting_to"
                          />
                          <ErrorMessage name="offer.reporting_to" component="div" className="error-message bg-danger-alert" />
                      </div>

                      <div className="col-md-12 mt-3">
                          <label htmlFor="location" className="form-label">location</label>
                          <Field
                              type="text"
                              name="offer.location"
                              id="location"
                              className="form-control"
                              placeholder="Enter location"
                          />
                          <ErrorMessage name="offer.location" component="div" className="error-message bg-danger-alert" />
                      </div>
                      <div className="col-md-12 mt-3">
                          <label htmlFor="package" className="form-label">package</label>
                          <Field
                              type="text"
                              name="offer.package[]"
                              id="package"
                              className="form-control"
                              placeholder="Enter package"
                          />
                          <ErrorMessage name="offer.package[]" component="div" className="error-message bg-danger-alert" />
                      </div>

                      <div className="col-md-12 mt-3">
                          <label htmlFor="salary" className="form-label">salary</label>
                          <Field
                              type="nubmer"
                              name="offer.salary"
                              id="salary"
                              className="form-control"
                              placeholder="Enter salary"
                          />
                          <ErrorMessage name="offer.salary" component="div" className="error-message bg-danger-alert" />
                      </div>
                      <div className="col-md-12 mt-3">
                          <label htmlFor="other_financially" className="form-label">other_financially</label>
                          <Field
                              type="nubmer"
                              name="offer.other_financially"
                              id="other_financially"
                              className="form-control"
                              placeholder="Enter other_financially"
                          />
                          <ErrorMessage name="offer.other_financially" component="div" className="error-message bg-danger-alert" />
                      </div>
                      <div className="col-md-12 mt-3">
                          <label htmlFor="working_days" className="form-label">working_days</label>
                          <Field
                              type="nubmer"
                              name="offer.working_days"
                              id="working_days"
                              className="form-control"
                              placeholder="Enter working_days"
                          />
                          <ErrorMessage name="offer.working_days" component="div" className="error-message bg-danger-alert" />
                      </div>
                      <div className="col-md-12 mt-3">
                          <label htmlFor="working_hours" className="form-label">working_hours</label>
                          <Field
                              type="nubmer"
                              name="offer.working_hours"
                              id="working_hours"
                              className="form-control"
                              placeholder="Enter working_hours"
                          />
                          <ErrorMessage name="offer.working_hours" component="div" className="error-message bg-danger-alert" />
                      </div>
                      <div className="col-md-12 mt-3">
                          <label htmlFor="notice_period" className="form-label">notice_period</label>
                          <Field
                              type="nubmer"
                              name="offer.notice_period"
                              id="notice_period"
                              className="form-control"
                              placeholder="Enter notice_period"
                          />
                          <ErrorMessage name="offer.notice_period" component="div" className="error-message bg-danger-alert" />
                      </div>
                      <div className="col-md-12 mt-3">
                          <label htmlFor="managing_name_partner" className="form-label">managing_name_partner</label>
                          <Field
                              type="nubmer"
                              name="offer.managing_name_partner"
                              id="managing_name_partner"
                              className="form-control"
                              placeholder="Enter managing_name_partner"
                          />
                          <ErrorMessage name="offer.managing_name_partner" component="div" className="error-message bg-danger-alert" />
                      </div>
                      <div className="col-md-12 mt-3">
                          <label htmlFor="acceptance_date" className="form-label">acceptance_date</label>
                          <Field
                              type="date"
                              name="offer.acceptance_date"
                              id="acceptance_date"
                              className="form-control"
                              placeholder="Enter acceptance_date"
                          />
                          <ErrorMessage name="offer.acceptance_date" component="div" className="error-message bg-danger-alert" />
                      </div>

                      <div>
    

        </div>


                  </div>
                  
                  <button
                      type="submit"
                      disabled={isLoading}
                      className="mb-3 active-button border-0 px-4 mt-3 pt-2 pb-2 fw-bold"
                  >
                      Submit
                  </button>
              </div>
          </Form>
      )}
  </Formik>
  
    )}
</Formik>




</>

                //     <div className="mt-3 container">
                //       <h5 className="fe-bold text-color text-center mt-2 mb-2">ارسال عرض الي الموظف</h5>
                //       <div className="row">
                //       <input
                //     type="text"
                //     className="form-control w-100"
                //     value={companyCommentt}
                //     onChange={(e) => setCompanyCommentt(e.target.value)}
                //     placeholder="Enter Company Comment"
                // />
                //       </div>


                //       <button type="button" onClick={fetchDataInputt} disabled={isLoading} className="mb-3 active-button border-0 px-4 mt-3  pt-2 pb-2 fw-bold">Submit</button>


                //     </div>
                )}

            </div>
        </div>

  <Modal.Footer />
</Modal>

<Modal show={Showw} onHide={handleClosee}>
  <div className="d-flex justify-content-between mt-3 mx-3">
    <Modal.Title> <h2 className="fw-bold">HR AI</h2> </Modal.Title>
    <Modal.Header closeButton />
  </div>

<div className="mx-4 mt-5">
<input
                  type="text"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  className="form-control w-100 "
                  placeholder=  {t("administration")}
                />
                <button
                  onClick={sendComment}
                  className="btn text-color fw-bold active-button mt-4"
                  disabled={isCommentLoading} // تعطيل الزر أثناء التحميل
                >
                  {isCommentLoading ? (
                    <i class="fas fa-circle-notch fa-spin"></i>
                  ) : (
                    t("administration")
                  )}
                </button>
</div>

  <Modal.Footer />
</Modal>

     



 
    <div className="w-50"></div>

      </div>
    </>
  );
}
